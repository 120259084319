<template>
  <v-col @click="documentFocus()">
    <v-row>
      <v-col lg="8" md="9" sm="8" cols="12">
        <s-toolbar label="Reservas"></s-toolbar>
        <v-card style="height:480px;">
          <v-col>
            <v-row justify="center">
              <v-col lg="3" md="3" class="s-col-form">
                <s-date label="Fecha de Reserva" v-model="dateReserve"></s-date>
              </v-col>
              <v-col lg="3" md="3" class="s-col-form">
                <s-select
                  label="Seleccione Plato"
                  v-model="MltID"
                  :items="itemsMeals"
                  item-value="MltID"
                  item-text="TypeMealText"
                  dense
                  outlined
                  hide-details
                >
                </s-select>
              </v-col>
              <v-col lg="3" md="3" class="s-col-form">
                <s-text autofocus  label="Documento" ref="textDNI" @keyupEnter="loadPerson" v-model="parameters.number"></s-text>
              </v-col>
              <v-col lg="2" md="2" class="s-col-form"
                ><v-btn outlined block style="margin-top:16px;" small color="info" @click="clearVisit()">Visita</v-btn></v-col
              >
            </v-row>
          </v-col>
          <v-col v-if="person != null">
            <v-sheet height="330">
              <p class="text-center" style="color:var(--v-primary-base); font-size:18px;margin:0px;">
                <b> {{ this.numberDocument }} </b>
              </p>
              <v-sheet style="padding:10px; padding-top:0px;" tile class="d-flex">
                <v-spacer></v-spacer>
                <h2 v-if="person.NtpPaternalSurname != null" style="color:#616161; font-size:26px;margin-top:-10px;">
                  {{ person.NtpPaternalSurname }}
                  {{ person.NtpMaternalSurname }} {{ person.NtpName }}
                </h2>
                <v-spacer></v-spacer>
              </v-sheet>
              <v-calendar
                @click:event="showEvent"
                :events="events"
                locale="es"
                type="custom-weekly"
                color="primary"
                :start="start"
                :end="end"
                :event-color="getEventColor"
              >
                <template v-slot:event="{ event }">
                  <div>
                    <p class="text-center" style="font-size:11px;" @contextmenu="showRightEvent({ nativeEvent: $event, event: event })">
                      <b> {{ event.name }}</b>
                    </p>
                  </div>
                </template>
              </v-calendar>
              <v-menu
                v-if="selectedEvent != null && selectedOpen"
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar dense :color="selectedEvent.color" dark>
                    <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  </v-toolbar>
                  <v-card-actions>
                    <v-row justify="center">
                      <v-btn dark fab style="margin-top:7px !important" color="success" @click="create(selectedEvent, true)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <h1
                        class="text-center"
                        style="font-size:48px !important;padding: 0px 30px 0px 30px !important;
              "
                      >
                        {{ countReservesByEvent }}
                      </h1>
                      <v-btn dark fab style="margin-top:7px !important" color="red" @click="reserveMinus(selectedEvent)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-card>
      </v-col>
      <!-- TIEMPO REAL -->
      <v-col sm="4" cols="12" md="3" lg="4" v-if="mealCurrent != 0">
        <v-row>
        <v-col style="padding: 10px">
          <v-img style="border-radius:6px;"
            v-if="image == ''"
            height="300"
            src="./../../../public/static/User.png"
          ></v-img>
          <v-img v-else style="border-radius:10px;"  height="300" :src="image"></v-img>
        </v-col>
        </v-row>
        <v-row>
          <v-col>
             <time-real-reserve style="border-radius:5px !important;" :MltID="mealCurrent" :columnMenu="12">
        </time-real-reserve>

          </v-col>
        </v-row>
       
      </v-col>
    </v-row>
    <!-- RESERVA DE VISITA -->
    <v-dialog v-model="showVisit" v-if="showVisit" persistent max-width="450">
      <v-card>
        <v-card-title>Reservar Visita </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <v-row style="padding-top:12px;">
              <v-col class="s-col-form">
                <s-toolbar-person
                  addTypeSex
                  autofocus
                  v-model="numberDocument"
                  @returnPerson="returnPersonVisit($event)"
                  label="Persona"
                ></s-toolbar-person>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="personVisit != null" class="s-col-form">
                <s-text disabled v-model="personVisit.NtpFullName" label="Persona"></s-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="s-col-form">
                <s-date v-model="dateVisit" label="Fecha de Reserva"></s-date>
              </v-col>
              <v-col class="s-col-form">
                <s-select
                  label="Selecciona Plato"
                  v-model="MltIDVisit"
                  :items="typeMeals"
                  noDefault
                  item-text="TypeMealText"
                  item-value="MltID"
                  dense
                  hide-details
                  outlined=""
                ></s-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="s-col-form" style="padding-bottom:0px;">
                <s-select-definition label="Tipo Costo" :def="1045" v-model="TypeCost"></s-select-definition>
              </v-col>
              <v-col class="s-col-form" style="padding-bottom:0px;">
                <s-select-area label="Centro de Costos" centercost clearable full v-model="centercost" :typeCost="TypeCost" noDefault></s-select-area>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="s-col-form" style="padding-bottom:0px;">
                <s-select-business-line clearable v-model="BslID" noDefault :cceID="centercost" full autocomplete></s-select-business-line>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel()">Cancelar</v-btn>
          <v-btn color="accent" @click="reserveVisit()">Reservar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import TimeRealReserve from "./../../views/DinningHall/TimeRealReserve";
import SToolbarPerson from "./../../components/Utils/Person/sToolbarPerson";
import Service from "./../../services/DinningHall/MenuTypeService";
import MealTypeService from "./../../services/DinningHall/MealTypeService";
import MealConsumptionService from "./../../services/DinningHall/MealConsumptionService";
import HelperService from "./../../services/HelperService";

export default {
  name: "ReserveAdmin",
  components: { SToolbarPerson, TimeRealReserve },
  props: {
    urlEdit: String,
    urlAdd: String,
    obj: Object,
    orderSave: Boolean,
  },
  data: () => ({
    image: "",
    BslID: 0,
    creating: false,
    personVisit: null,
    centercost: 0,
    persona: {},
    dateVisit: null,
    MltIDVisit: 0,
    MltID: 0,
    typeMeals: [],
    itemsMeals: [{ TypeMealText: "NINGUNO", MltID: 0 }],
    showVisit: false,
    start: new Date().toISOString().substr(0, 10),
    selectedElement: null,
    selectedOpen: false,
    selectedEvent: null,
    end: new Date().toISOString().substr(0, 10),
    events: [],
    reserves: [],
    item: null,
    meals: [],
    mealSelect: null,
    editVisible: false,
    TypeCost: "",
    area: { value: 0 },
    isVisit: 0,
    numberDocument: "",
    reserves: [],
    mealCurrent: 0,
    programaciones: [],
    parametersSelect: { term: "" },
    mealSelect: { editable: false },
    addDays: 0,
    dayWeek: null,
    nDays: null,
    daysMonth: null,
    nowDate: "",
    dateReserve: new Date().toISOString().substr(0, 10),
    numberMonth: 0,
    numberYear: 0,
    show: 0,
    disabled: false,
    parameters: { type: 0, number: "" },
    person: null, // NtpFullName, NtpID, PrsNumberDocument
    selected: [],
    mealTypes: [],
    dates: [],
    daysReserve: [],
    mealsReserve: [],
  }),

  computed: {
    countReservesByEvent() {
      let count = this.person.reserves.filter((x) => {
        return x.MltID == this.selectedEvent.MltID && this.$moment(x.McsReservationDate).format("YYYY-MM-DD") == this.selectedEvent.start;
      }).length;
      if (count == 0) this.events.find((x) => x.start == this.selectedEvent.start && x.MltID == this.selectedEvent.MltID).color = "#BDBDBD";

      return count;
    },
  },
  methods: {

    documentFocus() {
      this.$refs.textDNI.focus();
    },
    clearVisit() {
      this.showVisit = true;
      this.personVisit = null;
      this.TypeCost = 0;
      this.centercost = 0;
      this.MltIDVisit = this.MltID;
    },
    cancel() {
      this.personVisit = null;
      this.showVisit = false;
      this.person = null;
      this.numberDocument = "";
      this.TypeCost = 0;
      this.centercost = 0;
      this.$nextTick().then(() => {
        this.$refs.textDNI.focus();
      });
    },
    returnPersonVisit(dataperson) {
      this.personVisit = dataperson;
      if (dataperson != null) {
        console.log(this.personVisit);
        this.personVisit = { ...dataperson };
        // this.numberDocument=this.personVisit.NtpDocumentNumber;
      }
    },

    reserveMinus(event) {
      event.McsReservationDate = event.start;
      event.NtpID = this.person.NtpID;
      MealConsumptionService.reserveMinus(event, localStorage.getItem("UsrID")).then(() => {
        this.loadReserve();
      });
    },

    getEventColor(event) {
      return event.color;
    },
    setSelectMealEvent(item) {
      this.mealSelect = item;
    },
    getDatesReserve() {
      this.daysReserve = [];
      this.mealsReserve = [];
      for (let i = -1; i < 7; i++) {
        let day = {
          value: i,
          date: this.$moment(new Date()).add(i, "days"),
          visible: true,
        };
        day.numberDay = parseInt(day.date.format("DD"));
        this.daysReserve.push(day);
        this.mealTypes.forEach((element) => {
          let day2 = {
            value: i,
            color: element.color,
            start: this.$moment(new Date())
              .add(i, "days")
              .format("YYYY-MM-DD"),
            MltID: element.MltID,
            visible: true,
            name: element.TypeMealText,
            IsSelected: false,
          };
          this.mealsReserve.push(day2);
        });
      }
    },
    showRightEvent({ nativeEvent, event }) {
      if (event.color == "accent") {
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;

          setTimeout(() => (this.selectedOpen = true), 10);
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 10);
        } else open();
      }
      nativeEvent.preventDefault();
    },
    showEvent({ nativeEvent, event }) {
      this.MltIDVisit = event.MltID;
      this.dateVisit = event.start;
      this.selectedElement = nativeEvent.target;
      if (event.color == "accent") {
        event.All = 1;
        this.reserveMinus(event);
      } else this.create(event);
      nativeEvent.stopPropagation();
    },
    reserveVisit() {
      this.$fun;

      this.person = this.personVisit;

      if (
        this.centercost != 0 &&
        this.BslID != 0 &&
        this.centercost != null &&
        this.BslID != null &&
        this.personVisit != null &&
        this.TypeCost != null
      ) {
        this.person.NtpDocumentNumber = this.numberDocument;
        this.create({
          MltID: this.MltIDVisit,
          start: this.dateVisit,
          CceID: this.centercost,
          BslID: this.BslID,
          McsIsVisit: 1,
        });
      } else this.$fun.alert("Faltan Datos", "warning");
    },
    create(item, isDoble) {
      let obj = {
        TypeCost: this.TypeCost,
        McsID: 0,
        MndID: 0,
        MltID: item.MltID,
        McsIsVisit: 0,
        MntID: 0,
        NtpID: "",
        McsPersonName: "",
        McsPersonMaternalSurname: "",
        McsPersonPaternalSurname: "",
        McsPersonDocumentNumber: this.numberDocument,
        McsReservationDate: item.start,
        TypeAccessReserve: isDoble == true ? 9 : 1,
      };
      if (item.CceID) {
        obj.CceID = item.CceID;
        obj.McsIsVisit = 1;
        obj.BslID = item.BslID;
      }
      MealConsumptionService.save(obj, localStorage.getItem("UsrID")).then(
        (r) => {
          this.creating = false;
          this.loadReserve();
          this.showVisit = false;
          this.$fun.alert("Agregado Correctamente", "success");
          this.$refs.textDNI.focus();
        },
        (e) => {
          this.loadReserve();
          this.$refs.textDNI.focus();
          this.creating = false;
          if (e.status == 400) {
            if (e.data.Message == this.numberDocument + " NO ES TRABAJADOR") {
              this.showVisit = true;
            }
          }
        }
      );
    },
    loadReserve(create) {
      MealConsumptionService.ReservesPerson(this.numberDocument).then((r) => {
        this.getDatesReserve();
        this.events = this.mealsReserve;
        this.person = r.data;
        this.numberDocument = this.person.NtpDocumentNumber;
        if (this.person != null)
          if (this.person.reserves.length > 0)
            this.person.reserves.forEach((e) => {
              let i = this.events.find((x) => x.start == this.$moment(e.McsReservationDate).format("YYYY-MM-DD") && x.MltID == e.MltID);
              if (i != null) {
                i.McsID = e.McsID;
                i.color = "accent";
                //begin photo
                HelperService.getPhotoWorker( this.numberDocument, this.$fun.getUserID())
                  .then((rsp) => {
                    const blob = new Blob([rsp.data], {
                      type: "image/jpg",
                    });
                     
                    let image = blob;
                    var reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onloadend = () => {
                      this.image = reader.result;
                    };
                  }); 
                  //end foto
              }
            });

        this.parameters.number = "";
      });
    },
    loadPerson() {
      if (!this.creating) {
        this.dateVisit = this.dateReserve;
        this.MltIDVisit = this.MltID;
        if (this.parameters.number.trim().length == 0) return;
        this.numberDocument = this.parameters.number;
        this.creating = true;
        if (this.MltID != 0) this.create({ MltID: this.MltID, start: this.dateReserve });
        else {
          this.creating = false;
          this.loadReserve();
        }
      }

      // this.loadReserve(true);
    },
    getMealCurrent(meals) {
      let current = meals.find(
        (x) =>
          (this.$moment(x.MltBeginHour).format("HH:mm") < this.$moment().format("HH:mm") &&
            this.$moment(x.MltEndHour).format("HH:mm") > this.$moment().format("HH:mm")) ||
          (((this.$moment(x.MltBeginHour).format("HH:mm") < this.$moment().format("HH:mm") &&
            this.$moment(x.MltEndHour).format("HH:mm") < this.$moment().format("HH:mm")) ||
            (this.$moment(x.MltEndHour).format("HH:mm") > this.$moment().format("HH:mm") &&
              this.$moment(x.MltBeginHour).format("HH:mm") > this.$moment().format("HH:mm"))) &&
            this.$moment(x.MltEndHour).format("HH:mm") < this.$moment(x.MltBeginHour).format("HH:mm"))
      );
      return current != null ? current.MltID : 0;
    },
    loadMealTypes() {
      this.editVisible = false;
      this.mealTypes = [];
      let id = 0;
      MealTypeService.list().then((result) => {
        this.typeMeals = result.data;
        this.mealCurrent = this.getMealCurrent(this.typeMeals);
        this.itemsMeals.push(...result.data);
        result.data.forEach((e) => {
          e.disabled = false;
          e.color = "#BDBDBD";
          e.visible = true;
          this.mealTypes.push(e);
          this.getDatesReserve();
        });
        this.MltID = this.mealCurrent;
      });
    },
  },

  created() {
    let d = new Date();
    d.setDate(d.getDate() + 7);
    this.end = this.$moment(d).format("YYYY-MM-DD");
    this.start = this.$moment()
      .add(-1, "days")
      .format("YYYY-MM-DD");
    this.nowDate = this.$moment(new Date());
    this.dateReserve = this.$moment(new Date()).format("YYYY-MM-DD");
    this.loadMealTypes();
  },

  mounted() {
    let fecha = new Date();
    fecha.setDate(fecha.getDate() + 1);
  },
};
</script>

<style scoped>
.h + .v-date-picker-table {
  height: 150px !important;
}

.date + .v-btn {
  height: 20px !important;
}
</style>
